.dashboard {
    display: grid;
    min-height: 90vh;
}

.loading {
    align-self: center;
    justify-self: center;
}

.dashboard__top {
    display: flex;
    justify-content: space-between;
    height: 195px;
    margin-top: $xl-size;
}

.dashboard__top-summoner {
    align-self: center;
    display: grid;
    grid-template-columns: 1fr 1fr;

    h2 {
        align-self: center;
    }

    .dashboard__top-summoner-icon {
        position: relative;
        width: 131px;

        p {
            align-items: center;
            background: $dark-background;
            border-radius: 99px;
            border: 1px solid rgba(white, 0.3);
            color: white;
            display: grid;
            min-height: 30px;
            justify-items: center;
            left: 85px;
            top: 65px;
            position: absolute;
            min-width: 30px;
        }

        .icon {
            height: 100px;
            width: 100px;
        }

        .icon-border {
            height: 120px;
            left: -10px;
            top: -10px;
            position: absolute;
            width: 120px;
        }
    }
}

.dashboard__top-rank {
    background: $dark-background;
    border: 1px solid rgba(white, 0.1);
    display: flex;
    justify-content: space-between;
    padding: $m-size;

    div > span {
        color: rgba(white, 0.4);
        font-size: 1.2rem;
    }

    h4 {
        margin: 10px 0;
    }

    p {
        color: rgba(white, 0.6);
        margin: $xs-size 0;
    }

    strong {
        color: rgba(white, 0.6);
        font-weight: 400;

        span {
            color: $accent;
            text-transform: uppercase;
        }
    }

    img {
        align-self: center;
        height: 120px;
        width: 120px;
    }

    .unranked {
        display: grid;
        height: 70%;

        h4 {
            align-self: center;
        }
    }
}

.dashboard__bottom {
    display: flex;
    justify-content: space-between;
    margin-top: $xl-size;
}

.mastery {
    background: $dark-background;
    height: 537px;
    padding: $m-size;

    .mastery__champion {
        display: flex;
        justify-content: grid;
        margin: $s-size 0;

        img {
            height: 70px;
            margin-right: $xs-size;
            width: 70px;
        }

        div {
            justify-self: left;
        }
    }

    span {
        color: $blue;
    }

    p {
        color: rgba(white, 0.5);
        margin: 0;
    }

    h4 {
        border-bottom: 1px solid rgba(white, 0.1);
        margin-top: 0;
    }
}

.no-summoner {
    align-self: center;
    color: $blue;
    font-size: 3rem;
    justify-self: center;
    text-transform: uppercase;
}