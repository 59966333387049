.container {
    margin: 0 auto;
    padding: 0 $m-size;
    max-width: 140rem;
}

.container-small {
    margin: 0 auto;
    padding: 0 $m-size;
    max-width: 100rem;
}