// Colors
$background: #1b2838;
$dark-background: darken($background, 2%);
$blue: #3676c4;
$accent: #f35353;
$white: #ECF0F1;

// Font Size
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;

// Spacing
$xs-size: 0.8rem;
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;

// Misc
$transition: 0.3s all ease;

// Mixin

// Breakpoints
$desktop-breakpoint: 45rem;